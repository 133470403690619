import React from "react"
import { graphql, Link } from "gatsby"
import { motion } from "framer-motion"
import Loadable from "@loadable/component"
import Img from "gatsby-image"
import ReactMapGL from 'react-map-gl'
import {Helmet} from 'react-helmet'

import Demo from "../components/Demo"
import DemoTable from "../components/DemoTable"
import Layout from "../components/layouts/Layout"
import FeatureTile from "../components/FeatureTile"
import FeatureBlock from "../components/FeatureBlock"
import DemoStyles from "../components/Demo.module.scss"
import Button from "../components/Button"

const PlantModel = Loadable(() => import("../components/PlantModel"), {
  fallback: <div style={{height:22+'rem', paddingTop: 80+'px'}}>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{margin: 'auto auto', height: 50+'px'}}>
      <path opacity=".5" d="M16 0 A16 16 0 0 0 16 32 A16 16 0 0 0 16 0 M16 4 A12 12 0 0 1 16 28 A12 12 0 0 1 16 4"/>
      <path style={{fill: '#ffffff'}} d="M16 0 A16 16 0 0 1 32 16 L28 16 A12 12 0 0 0 16 4z">
        <animateTransform attributeName="transform" type="rotate" from="0 16 16" to="360 16 16" dur="0.8s" repeatCount="indefinite" />
      </path>
    </svg>
  </div>
})

const variants = {
  hidden: { opacity: 0.2 },
  visible: { opacity: 1 },
}

const header = (data) => (
  <div className={`bg-gray-100`}>
    <div className="w-full max-w-screen-xl mx-auto px-6">
      <div className="flex flex-wrap flex-row">
        <div className="w-full flex sm:w-1/2">
          <motion.div 
            className="pt-12 text-left"
            initial="hidden"
            animate="visible"
            variants={variants}
            >
            <h1 className="text-6xl text-black" style={{maxWidth: 450+'px', lineHeight: 90+'px', marginBottom: 30}}>Smart packaging made simple</h1>
            <p className="text-xl text-gray-800 font-normal max-w-md">
              Engage your customers, collect real-time intelligence and prove authenticity <br /> with one QR code.
            </p>

            <div className="pt-12 pb-12">
              <a style={{"cursor": "pointer"}} href="mailto:will@counterset.com" className="items-center btn btn-black">Request a demo</a>
            </div>
          </motion.div>
        </div>
        <div className="hidden md:block md:w-1/2 mt-16 pl-2" style={{height: 420+'px', overflow: 'hidden', direction: 'rtl'}}>
          <a target="_blank" href="https://counterset.com/p/5iU1E4uSTPWvPIHB-bUZHw"><Img fixed={data.qr.fixed}></Img></a>
        </div>
      </div>
      
    </div>
  </div>
)

export default ({data}) => {
  return (
    <Layout header={header(data)}>
      <Helmet>
        <title>Smart Packaging Made Simple</title>
        <meta name="description" content="Engage your customers, collect real-time intelligence and prove authenticity with one QR code." />
        <meta property="og:description" content="CounterSet makes it simple to create secure, mobile first websites for products." />

        
      </Helmet>
      <p className="mt-6"></p>
      <FeatureBlock
        title="Engage with your customers"
        reverse
        img={(<motion.div 
          className="hidden sm:flex sm:w-1/2 justify-end relative pt-6"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeIn", duration: 0.5 }}
          className="overflow-hidden" 
          style={{
            borderRadius: 16+'px',
            height: 22+'rem',
            width: 21.5+'rem',
          }}>
            <PlantModel />
        </motion.div>)}
        learnTo={"/features"}
      ><p className="text-lg">
          CounterSet lets you build innovative marketing and augmented reality experiences with no code required.
      </p>
      </FeatureBlock>
      <FeatureBlock
        title="Collect real-time intelligence from your products"
        img={(<Img fluid={data.map.fluid}></Img>)}
      >
        <p className="mb-4 text-lg">
          Geo-location of customer scans lets you discover customer hotspots and determine which stores should be stocking your products.
        </p>

      </FeatureBlock>

    <FeatureBlock
      title="Prove authenticity"
      img={(<Img fluid={data.code.fluid}></Img>)}
      reverse
    >
      <p className="mb-4 text-lg">
        CounterSet unique codes and analytics create an easy and effective way to deter counterfeiters and reassure potential customers.      </p>
      <p>
      </p>
    </FeatureBlock>

  </Layout>
  )
}

export const query = graphql`
query Screens {
  qr: imageSharp(fixed: {originalName: {eq: "banner.jpg"} }) {
    fixed(height: 420) {
      ...GatsbyImageSharpFixed
    }
  }
  map: imageSharp(fluid: {originalName: {eq: "map.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  } 
  code: imageSharp(fluid: {originalName: {eq: "code.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  batch: imageSharp(fluid: {originalName: {eq: "screen-batch.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
  template: imageSharp(fluid: {originalName: {eq: "screen-template.jpg"} }) {
    fluid {
      ...GatsbyImageSharpFluid
    }
  }
}
`